import { action, computed, makeObservable, observable } from 'mobx';

import _ from 'lodash';
import { DateTime } from 'luxon';

import {
  DEFAULT_TIMELINE_END_DATE,
  DEFAULT_TIMELINE_START_DATE,
  FilterQueryParams,
  FiltersModelDefaults,
  ProcessStatus,
  ProcessType,
} from 'utils/constants';
import { DecodeBase64, EncodeBase64, getLastNumDaysDates, isLastNumDaysAligned } from 'utils/utils';

export type FiltersJSON = {
  location: string[] | null;
  process_name: string[] | null;
  process_type: string[] | null;
  pipeline_errors_only: boolean | null;
  reprocessed: boolean | null;
  process_status: number[] | null;
  data_status: number[] | null;
  unarchived_only: boolean | null;
  user: string[] | null;

  show_regular_runs: boolean | null;
  show_reprocessing_runs: boolean | null;

  use_last_num_days_field: boolean | null;
  last_num_days: number | null;
  start_date: string | null;
  end_date: string | null;
};

interface FiltersProps {
  /** Set of filter values to initialize filter state */
  filters?: FiltersJSON | null;
  useUrlFilters?: boolean;
}

export interface FiltersInterface {
  processType: Set<ProcessType>;
  processName: Set<string>;
  location: Set<string>;
  user: Set<string>;
  processStatus: Set<ProcessStatus>;
  reprocessedOnly: boolean;
  unarchivedOnly: boolean;
  pipelineErrorsOnly: boolean;
  showRegularRuns: boolean;
  showReprocessingRuns: boolean;

  useLastNumDaysField: boolean;
  lastNumDays: number | null;
  timelineEndDate: DateTime | null;
  timelineStartDate: DateTime | null;
}
export type ToggleFilterKeys = keyof Pick<
  FiltersInterface,
  'pipelineErrorsOnly' | 'showRegularRuns' | 'showReprocessingRuns'
>;

export enum ToggleRunType {
  Regular = 'regular',
  Reprocessing = 'reprocessing',
  All = 'all',
}

export default class Filters implements FiltersInterface {
  @observable processType;
  @observable processName;
  @observable location;
  @observable user;
  @observable processStatus;
  @observable reprocessedOnly;
  @observable unarchivedOnly;
  @observable pipelineErrorsOnly;
  @observable showRegularRuns;
  @observable showReprocessingRuns;

  @observable useLastNumDaysField;
  @observable lastNumDays;
  @observable timelineEndDate;
  @observable timelineStartDate;

  // Track number of changes made
  @observable changeCounter: number = 0;
  @observable disableApplyButton: boolean = false;

  private readonly urlDates: [string | null, string | null];

  constructor({ filters, useUrlFilters }: FiltersProps) {
    // Get filters from URL params
    const { filter: urlFilters, urlDateExists } = this._decodeFilterUrl();
    const predefinedFilters = urlFilters && useUrlFilters ? urlFilters : filters;

    this.processType = new Set((predefinedFilters?.process_type as ProcessType[]) ?? FiltersModelDefaults.processType);
    this.processName = new Set(predefinedFilters?.process_name ?? FiltersModelDefaults.processName);
    this.location = new Set(predefinedFilters?.location ?? FiltersModelDefaults.location);
    this.user = new Set(predefinedFilters?.user ?? FiltersModelDefaults.user);
    this.pipelineErrorsOnly = predefinedFilters?.pipeline_errors_only ?? FiltersModelDefaults.pipelineErrorsOnly;
    this.showRegularRuns = predefinedFilters?.show_regular_runs ?? FiltersModelDefaults.showRegularRuns;
    this.showReprocessingRuns = predefinedFilters?.show_reprocessing_runs ?? FiltersModelDefaults.showReprocessingRuns;

    // Retrieve saved past-days field values
    this.useLastNumDaysField = predefinedFilters?.use_last_num_days_field ?? FiltersModelDefaults.useLastNumDaysField;
    this.lastNumDays = predefinedFilters?.last_num_days ?? FiltersModelDefaults.lastNumDays;

    // Uncheck last-days checkbox if dates in URL are unaligned w/ current value.
    // Otherwise, recompute dates based on last-days value
    if (predefinedFilters?.start_date && predefinedFilters?.end_date) {
      const predefinedStartDate = DateTime.fromISO(predefinedFilters.start_date);
      const predefinedEndDate = DateTime.fromISO(predefinedFilters.end_date);

      const datesAreAligned = isLastNumDaysAligned(this.lastNumDays, predefinedStartDate, predefinedEndDate);

      const uncheckLastDaysField = urlDateExists && !datesAreAligned;
      const recomputeDateRange = !urlDateExists && this.useLastNumDaysField && this.lastNumDays;

      if (uncheckLastDaysField) {
        this.useLastNumDaysField = false;
      } else if (recomputeDateRange) {
        const lastDaysDate = getLastNumDaysDates(Number(this.lastNumDays)); // `this.lastNumDays` should already be validated as a number by this point
        predefinedFilters.start_date = lastDaysDate.begin && lastDaysDate.begin.toISODate();
        predefinedFilters.end_date = lastDaysDate.end && lastDaysDate.end.toISODate();
      }
    }

    this.processStatus = new Set(predefinedFilters?.process_status ?? FiltersModelDefaults.processStatus);

    // Save dates defined in url
    const urlParams = new URLSearchParams(window.location.search);
    this.urlDates = [urlParams.get(FilterQueryParams.START_DATE), urlParams.get(FilterQueryParams.END_DATE)];

    // Initialize start/end dates for filter; fall back to defaults if no predefined filters are available
    if (predefinedFilters) {
      const { start_date, end_date } = predefinedFilters;
      this.timelineEndDate = end_date ? DateTime.fromISO(end_date) : null;
      this.timelineStartDate = start_date ? DateTime.fromISO(start_date) : null;
    } else {
      this.timelineEndDate = FiltersModelDefaults.timelineEndDate;
      this.timelineStartDate = FiltersModelDefaults.timelineStartDate;
    }

    // TODO: Filters below not needed anymore
    this.reprocessedOnly = predefinedFilters?.reprocessed ?? FiltersModelDefaults.reprocessedOnly;
    this.unarchivedOnly = predefinedFilters?.unarchived_only ?? FiltersModelDefaults.unarchivedOnly;

    makeObservable(this);
  }

  @computed get query(): string {
    const json_to_stringify = this.toJSON();

    // Encode object to JSON string
    const query_utf8 = JSON.stringify(json_to_stringify);

    // Encode JSON string to base64 string
    const query_base64: string = EncodeBase64(query_utf8);

    return query_base64;
  }

  @action
  incrementChangeCounter() {
    this.changeCounter += 1;
  }

  @action
  resetChangeCounter() {
    this.changeCounter = 0;
  }

  @action
  toggleUseLastNumDaysField(checked?: boolean) {
    if (checked !== undefined) {
      this.useLastNumDaysField = checked;
    } else {
      this.useLastNumDaysField = !this.useLastNumDaysField;
    }
    this.incrementChangeCounter();
  }

  @action
  setLastNumDays(numDays: number | null) {
    this.lastNumDays = numDays;
    this.incrementChangeCounter();
  }

  @action
  setTimelineStartDate(startDate: DateTime | null) {
    this.timelineStartDate = startDate;
    this.incrementChangeCounter();
  }

  @action
  setTimelineEndDate(endDate: DateTime | null) {
    this.timelineEndDate = endDate;
    this.incrementChangeCounter();
  }

  @action
  setTimelineDateRange(startDate: DateTime | null, endDate: DateTime | null) {
    this.timelineStartDate = startDate;
    this.timelineEndDate = endDate;
    this.incrementChangeCounter();
  }

  private _resetTimelineDateRange() {
    this.timelineEndDate = this.urlDates[1] ? DateTime.fromISO(this.urlDates[1]) : DEFAULT_TIMELINE_END_DATE;
    this.timelineStartDate = this.urlDates[0] ? DateTime.fromISO(this.urlDates[0]) : DEFAULT_TIMELINE_START_DATE;
  }

  @action setDisableApplyButton(disable: boolean) {
    this.disableApplyButton = disable;
  }

  @action addType(type: ProcessType) {
    this.processType.add(type);
    this.incrementChangeCounter();
  }
  @action removeType(type: ProcessType) {
    this.processType.delete(type);
    this.incrementChangeCounter();
  }

  @action addProcessName(name: string) {
    this.processName.add(name);
    this.incrementChangeCounter();
  }

  @action removeProcessName(name: string) {
    this.processName.delete(name);
    this.incrementChangeCounter();
  }

  @action updateProcessNames(names: string[]) {
    this.processName = new Set(names);
    this.incrementChangeCounter();
  }

  @action addLocation(location: string) {
    this.location.add(location);
    this.incrementChangeCounter();
  }

  @action removeLocation(location: string) {
    this.location.delete(location);
    this.incrementChangeCounter();
  }

  @action updateLocations(locations: string[]) {
    this.location = new Set(locations);
    this.incrementChangeCounter();
  }

  @action updateUsers(users: string[]) {
    this.user = new Set(users);
    this.incrementChangeCounter();
  }

  @action addProcessStatus(status: ProcessStatus) {
    this.processStatus.add(status);
    this.incrementChangeCounter();
  }

  @action removeProcessStatus(status: ProcessStatus) {
    this.processStatus.delete(status);
    this.incrementChangeCounter();
  }

  // TODO: make generic function to handle filter toggles
  @action toggleReprocessDisplay(to_show?: boolean) {
    if (to_show !== undefined) {
      this.reprocessedOnly = to_show;
    } else {
      this.reprocessedOnly = !this.reprocessedOnly;
    }
    this.incrementChangeCounter();
  }

  @action toggleUnarchivedDisplay(to_show?: boolean) {
    if (to_show !== undefined) {
      this.unarchivedOnly = to_show;
    } else {
      this.unarchivedOnly = !this.unarchivedOnly;
    }
    this.incrementChangeCounter();
  }

  @action toggleErrorsOnlyDisplay(to_show?: boolean) {
    if (to_show !== undefined) {
      this.pipelineErrorsOnly = to_show;
    } else {
      this.pipelineErrorsOnly = !this.pipelineErrorsOnly;
    }
    this.incrementChangeCounter();
  }

  @action toggleShowRegularRuns(to_show?: boolean) {
    if (to_show !== undefined) {
      this.showRegularRuns = to_show;
    } else {
      this.showRegularRuns = !this.showRegularRuns;
    }
    this.incrementChangeCounter();
  }

  @action toggleShowReprocessingRuns(to_show?: boolean) {
    if (to_show !== undefined) {
      this.showReprocessingRuns = to_show;
    } else {
      this.showReprocessingRuns = !this.showReprocessingRuns;
    }
    this.incrementChangeCounter();
  }

  isDefaultValues(dateRange: [DateTime | null, DateTime | null]): boolean {
    // const filterKeys = Object.keys(FiltersModelDefaults)

    if (!_.isEqual(this.processType, FiltersModelDefaults.processType)) return false;
    if (!_.isEqual(this.processName, FiltersModelDefaults.processName)) return false;
    if (!_.isEqual(this.location, FiltersModelDefaults.location)) return false;
    if (!_.isEqual(this.user, FiltersModelDefaults.user)) return false;
    if (!_.isEqual(this.reprocessedOnly, FiltersModelDefaults.reprocessedOnly)) return false;
    if (!_.isEqual(this.unarchivedOnly, FiltersModelDefaults.unarchivedOnly)) return false;
    if (!_.isEqual(this.showRegularRuns, FiltersModelDefaults.showRegularRuns)) return false;
    if (!_.isEqual(this.showReprocessingRuns, FiltersModelDefaults.showReprocessingRuns)) return false;
    if (!_.isEqual(this.pipelineErrorsOnly, FiltersModelDefaults.pipelineErrorsOnly)) return false;
    if (!_.isEqual(this.processStatus, FiltersModelDefaults.processStatus)) return false;
    if (!_.isEqual(this.useLastNumDaysField, FiltersModelDefaults.useLastNumDaysField)) return false;
    if (!_.isEqual(this.lastNumDays, FiltersModelDefaults.lastNumDays)) return false;

    // Compare date with default
    const [startDate, endDate] = dateRange;
    if (startDate || endDate) {
      // Return false if either date isn't null and doesn't equal default
      // TODO: Do we want to consider the date from the URL as the default or always have it be the fallback?
      if (!_.isEqual(startDate?.toISODate(), DEFAULT_TIMELINE_START_DATE?.toISODate())) return false;
      if (!_.isEqual(endDate?.toISODate(), DEFAULT_TIMELINE_END_DATE?.toISODate())) return false;
    }

    return true;
  }

  /** @todo This can just be a constant at the top of class */
  private get _urlParams(): URLSearchParams {
    return new URLSearchParams(window.location.search);
  }

  private _decodeFilterUrl(): { filter: FiltersJSON | null; urlDateExists: boolean } {
    const urlParams = this._urlParams;
    const filterString = urlParams.get(FilterQueryParams.FILTER);

    const urlDates = this._parseURLDates(urlParams);
    const urlDateExists = Boolean(urlDates.start_date || urlDates.end_date);

    const decodedFilter = filterString ? DecodeBase64(filterString) : null;
    const filter: FiltersJSON | null = decodedFilter ? JSON.parse(decodedFilter) : null;
    if (filter) {
      filter.start_date = urlDates.start_date;
      filter.end_date = urlDates.end_date;
    }

    return { filter, urlDateExists };
  }

  private _parseURLDates(urlParams: URLSearchParams = this._urlParams): Pick<FiltersJSON, 'start_date' | 'end_date'> {
    return {
      start_date: urlParams.get(FilterQueryParams.START_DATE),
      end_date: urlParams.get(FilterQueryParams.END_DATE),
    };
  }

  @action reset() {
    this.processType = FiltersModelDefaults.processType;
    this.processName = FiltersModelDefaults.processName;
    this.location = FiltersModelDefaults.location;
    this.user = FiltersModelDefaults.user;
    this.reprocessedOnly = FiltersModelDefaults.reprocessedOnly;
    this.unarchivedOnly = FiltersModelDefaults.unarchivedOnly;
    this.pipelineErrorsOnly = FiltersModelDefaults.pipelineErrorsOnly;
    this.showRegularRuns = FiltersModelDefaults.showRegularRuns;
    this.showReprocessingRuns = FiltersModelDefaults.showReprocessingRuns;
    this.processStatus = FiltersModelDefaults.processStatus;

    this.useLastNumDaysField = FiltersModelDefaults.useLastNumDaysField;
    this.lastNumDays = FiltersModelDefaults.lastNumDays;
    this._resetTimelineDateRange();

    this.changeCounter = 0;
  }

  toJSON(): FiltersJSON {
    let json_to_stringify: FiltersJSON = {
      process_name: null,
      process_type: null,
      location: null,
      pipeline_errors_only: null,
      reprocessed: null,
      process_status: null,
      data_status: null,
      user: null,
      unarchived_only: null,

      show_regular_runs: null,
      show_reprocessing_runs: null,

      use_last_num_days_field: null,
      last_num_days: null,
      start_date: null,
      end_date: null,
    };

    // Add process names to query
    json_to_stringify.process_name = Array.from(this.processName);

    // Add process types to query
    json_to_stringify.process_type = Array.from(this.processType);

    // Add locations to query
    json_to_stringify.location = Array.from(this.location);

    // Add users to query
    json_to_stringify.user = Array.from(this.user);

    // Add reprocessedOnly to query
    json_to_stringify.reprocessed = this.reprocessedOnly;

    // Add pipelineErrorsOnly to query
    json_to_stringify.pipeline_errors_only = this.pipelineErrorsOnly;

    // Add showRegularRuns to query
    json_to_stringify.show_regular_runs = this.showRegularRuns;

    // Add showReprocessingRuns to query
    json_to_stringify.show_reprocessing_runs = this.showReprocessingRuns;

    // Add process status to query
    json_to_stringify.process_status = Array.from(this.processStatus);

    // Add unarchivedOnly to query
    json_to_stringify.unarchived_only = this.unarchivedOnly;

    json_to_stringify.use_last_num_days_field = this.useLastNumDaysField;
    json_to_stringify.last_num_days = this.lastNumDays;
    json_to_stringify.start_date = this.timelineStartDate && this.timelineStartDate.toISODate();
    json_to_stringify.end_date = this.timelineEndDate && this.timelineEndDate.toISODate();

    return json_to_stringify;
  }
}
